.App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}

.App-header {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center;
  align-items: center;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  margin-top: 20px; /* Adds space between the heading and button */
  cursor: pointer;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

h1 {
  font-size: 48px;
  margin-bottom: 20px;
}
body {
  background-color: black;
}
.required:after {
	content: " *";
	color: red;
	font-size: 1rem;
  }
  .error-text {
    margin-bottom: 2em;
    font-size: 12px;
    text-align: left;
    color: #9f0002;
    font-weight: 600;
  }
  .error {
    margin-bottom: 2em;
    font-size: 12px;
    text-align: left;
    color: red;
    font-weight: 600;
  }